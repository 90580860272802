import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const SurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	dueDate: types.maybeNull(types.string),
});

const PostPreSurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	surveyDueDate: types.maybeNull(types.string),
});

const CourseSessionItem = types.model({
	sessionId: types.maybeNull(types.number),
	sessionName: types.maybeNull(types.string),
	sessionSNo: types.maybeNull(types.string),
	scheduleDateTime: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	sessionSurvey: types.array(SurveyItem),
	presenterId: types.maybeNull(types.number),
});

const BatchDaysScheduleItem = types.model({
	courseDaysId: types.maybeNull(types.number),
	courseDaysName: types.maybeNull(types.string),
	courseDaysSNo: types.maybeNull(types.string),
	scheduleDateTime: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	courseSession: types.array(CourseSessionItem),
});

const BatchItem = types.model({
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	hostName: types.maybeNull(types.string),
	hostEmail: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	isDisplayOnMarketPlace:types.maybeNull(types.boolean),
	displayOnMarketPlaceDisable:types.maybeNull(types.boolean),
	batchDaySchedule: types.maybeNull(types.array(BatchDaysScheduleItem)),
	courseBatchPostSurveys: types.maybeNull(types.array(PostPreSurveyItem)),
	courseBatchPreparatorySurveys: types.maybeNull(
		types.array(PostPreSurveyItem)
	),
	zoomMeetingId: types.maybeNull(types.string),
	zoomMeetingTitle: types.maybeNull(types.string),
	isZoomEnabled: types.maybeNull(types.boolean),
	isZoomPaused: types.maybeNull(types.boolean),
	isBatchEditable: types.maybeNull(types.boolean),
	isZoomButtonVisible: types.maybeNull(types.boolean),
});


export const CourseBatch = types
	.model({
		items: types.array(BatchItem),
		
		patientBatch: types.array(BatchItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.batchId === self.currentId
					);
				} else {
					return null;
				}
			},
			item(id) {
				if (id) {
					return self.items.find((item) => item.batchId === id);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseBatch`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadById: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseBatchById?id=${id}&sNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find((item) => item.batchId === id && item.batchSNo === sNo),
							data
						);
						self.currentId = id;
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			save: flow(function* save(data, snackbar) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCourseBatch`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400 || json.status === 404) {
								snackbar.showMessage(json?.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(self.current, json);
								self.updateContentType(CONTENT_TYPE.LIST);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/createCourseBatch`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.items.push(json);
							self.currentId = null;
							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							if (json?.errors) {
								snackbar.showMessage(
									"Please Enter Schedule Date and Time",
									null,
									null,
									{
										severity: "error",
									}
								);
							} else {
								snackbar.showMessage(json?.error, null, null, {
									severity: "error",
								});
							}
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			changeToggleShow_OnMarketplace: flow(function* changeToggleShow_OnMarketplace(
				data,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateCourseBatchMarketFlag`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						const updatedItem = json; // Use the entire JSON response
						const index = self.items.findIndex(item => item.batchId === updatedItem.batchId && item.batchSNo === updatedItem.batchSNo
						&& item.courseId === updatedItem.courseId && item.courseSNo === updatedItem.courseSNo);
						if (index !== -1) {
							applySnapshot(self.items[index], {...self.items[index],isDisplayOnMarketPlace:updatedItem.isDisplayonMarketPlace});
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							)
						}
						
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					})}
			}),

			removeBatch: flow(function* removeBatch(batch, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCourseBatch`,
						getRequestOptions("POST", {
							batchSNo: batch.batchSNo,
							batchId: batch.batchId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(batch);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getAssignedBatchToPatient: flow(function* getAssignedBatchToPatient(
				id,
				snackbar
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAssignedBatchToPatient?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.patientBatch, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			assignCourseToPatient: flow(function* assignCourseToPatient(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/assignCourseToPatient`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.patientBatch.push(json);
						callback();
						snackbar.showMessage("Course Assigned!!", null, null, {
							severity: "success",
						});
					} else {
						callback();
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					callback();
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			unassignCourseToPatient: flow(function* unassignCourseToPatient(
				data,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/unassignCourseToPatient`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						destroy(
							self.patientBatch.find(
								(item) => item.batchId === json.courseBatchId
							)
						);
						snackbar.showMessage(
							"Course Unassigned!!",
							null,
							null,
							{
								severity: "success",
							}
						);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
