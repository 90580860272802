import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";
import { blobToBase64, jsonToFormData } from "../../helpers";
import FileSaver from "file-saver";

const PresenterItem = types.model({
	id: types.number,
});

const CVItem = types.model({
	contentCreatorId: types.maybeNull(types.number),
	cvContentType: types.maybeNull(types.string),
	cvFileName: types.maybeNull(types.string),
	cvUrl: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const JoinActivityItem = types.model({
	presenterId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	presenterPrefix: types.maybeNull(types.string),
	presenterFirstName: types.maybeNull(types.string),
	presenterMiddleName: types.maybeNull(types.string),
	presenterLastName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	businessPhone: types.maybeNull(types.string),
	faxPhone: types.maybeNull(types.string),
	profession: types.maybeNull(types.string),
	company: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	countryId: types.maybeNull(types.number),
	stateId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	isPrimaryContact: types.maybeNull(types.boolean),
	primaryContactPrefix: types.maybeNull(types.string),
	primaryContactFirstName: types.maybeNull(types.string),
	primaryContactMiddleName: types.maybeNull(types.string),
	primaryContactLastName: types.maybeNull(types.string),
	primaryContactProfession: types.maybeNull(types.string),
	primaryContactCompany: types.maybeNull(types.string),
	primaryContactAddress1: types.maybeNull(types.string),
	primaryContactAddress2: types.maybeNull(types.string),
	primaryContactCity: types.maybeNull(types.string),
	primaryContactCountryId: types.maybeNull(types.number),
	primaryContactStateId: types.maybeNull(types.number),
	primaryContactZip: types.maybeNull(types.string),
	primaryContactPhone: types.maybeNull(types.string),
	primaryContactBusinessPhone: types.maybeNull(types.string),
	primaryContactFaxPhone: types.maybeNull(types.string),
	displayOnMarketingPage: types.maybeNull(types.boolean),
	legalConsent: types.maybeNull(types.boolean),
	biography: types.maybeNull(types.string),
	inviteStatus: types.maybeNull(types.number),
});

export const CoursePresenter = types
	.model({
		items: types.array(PresenterItem),
		joinActivityDetails: types.maybeNull(JoinActivityItem),
		headshot: types.maybeNull(types.string),
		cv: types.array(CVItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetAllPendingPresenterInvite`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						// self.items = json;
						return json;
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
				return [];
			}),

			getPresenterActivityInformation: flow(
				function* getPresenterActivityInformation(presenter) {
					self.status = "pending";
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/GetPresenterActivityInformation?presenterId=${presenter.presenterId}&courseId=${presenter.courseId}&courseSNo=${presenter.courseSNo}`,
							getRequestOptions("GET", null)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.joinActivityDetails = json;
							self.status = "done";
							self.loadCV();
							self.loadHeadshot();
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
							self.status = "error";
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
						self.status = "error";
					}
				}
			),

			getPresenterByEmail: flow(
				function* getPresenterByEmail(email) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/GetPresentersByEmail?email=${email}`,
							getRequestOptions("GET", null)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							return json;
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
						return null;
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
					return null;
				}
			),

			uploadCV: flow(function* uploadCV(data, callback=()=>{}) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.formFile", data);
					fd.append(
						"presenterId",
						self.joinActivityDetails?.presenterId
					);
					fd.append("courseId", self.joinActivityDetails?.courseId);
					fd.append("courseSNo", self.joinActivityDetails?.courseSNo);

					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AddPresenterCV`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.cv = [json];
						callback();
					} else {
						getEnv(self).notifier.enqueue(
							json?.error || json?.title,
							"error"
						);
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updatePresenterInformation: flow(
				function* updatePresenterInformation(
					payload,
					callback = () => {}
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/UpdatePresenterInformation`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							if (json.status === 0) {
								applySnapshot(self.joinActivityDetails, json);
								callback();
							} else {
								getEnv(self).notifier.enqueue(
									json?.error || json?.title,
									"error"
								);
							}
						} else {
							getEnv(self).notifier.enqueue(
								json?.error || json?.title,
								"error"
							);
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),
			
			savePresenterInformation: flow(
				function* updatePresenterInformation(
					payload,
					callback = () => {}
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/SavePresenterInformation`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							if (json.status === 0) {
								applySnapshot(self.joinActivityDetails, json);
								getEnv(self).notifier.enqueue(
									"Details Saved Successfully!",
									"success"
								);
								callback();
							} else {
								getEnv(self).notifier.enqueue(
									json?.error || json?.title,
									"error"
								);
							}
						} else {
							getEnv(self).notifier.enqueue(
								json?.error || json?.title,
								"error"
							);
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),

			addHeadshot: flow(function* addHeadshot(data, callback) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AddPresenterHeadshot`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.loadHeadshot();
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			loadHeadshot: flow(function* loadHeadshot() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetPresenterHeadShotById?presenterId=${self.joinActivityDetails?.presenterId}&courseId=${self.joinActivityDetails?.courseId}&courseSNo=${self.joinActivityDetails?.courseSNo}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const base64 = yield blobToBase64(blob);
						self.headshot = base64;
					} else {
						self.headshot = null;
					}
				} catch (error) {
					self.headshot = null;
				}
			}),

			loadCV: flow(function* loadCV() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetPresenterCVById?presenterId=${self.joinActivityDetails?.presenterId}&courseId=${self.joinActivityDetails?.courseId}&courseSNo=${self.joinActivityDetails?.courseSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if(json.status === 0){
							self.cv = [json];
						}
					} else {
						self.cv = [];
					}
				} catch (error) {
					self.cv = [];
				}
			}),

			removeHeadshot: flow(function* removeHeadshot() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/DeletePresenterHeadShot`,
						getRequestOptions("POST", {
							presenterId: self.joinActivityDetails?.presenterId,
							courseId: self.joinActivityDetails?.courseId,
							courseSNo: self.joinActivityDetails?.courseSNo,
						})
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							self.headshot = null;
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			removeCV: flow(function* removeCV(cv) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/DeletePresenterCVById`,
						getRequestOptions("POST", {
							"presenterId": self.joinActivityDetails?.presenterId,
							"courseId": self.joinActivityDetails?.courseId,
							"courseSNo": self.joinActivityDetails?.courseSNo
						  })
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							self.cv = []
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			getFileAsDownload: flow(function* getFileAsDownload(
				fileURL,
				fileContentType,
				fileName,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, fileName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),
		};
	});
